export const GIGLIST = [
    {
        id: 0, 
        date: '6/22/22',
        location: 'Greenroom 42 New York, NY',
        ticket: 'https://thegreenroom42.venuetix.com/show/details/ZCoquGtfA7zvtf2l3cQf/1655938800000'
    },
    {
        id: 1, 
        date: '6/25/22',
        location: 'Greenroom 42 New York, NY',
        ticket: 'https://thegreenroom42.venuetix.com/show/details/ZCoquGtfA7zvtf2l3cQf/1655938800000'
    },
    {
        id: 2, 
        date: '7/3/22',
        location: 'Anything Brooklyn, NY',
        ticket: 'https://thegreenroom42.venuetix.com/show/details/ZCoquGtfA7zvtf2l3cQf/1655938800000'
    },
    {
        id:3, 
        date: '7/9/22',
        location: 'Anything Brooklyn, NY',
        ticket: 'https://thegreenroom42.venuetix.com/show/details/ZCoquGtfA7zvtf2l3cQf/1655938800000'
    },
    {
        id: 4, 
        date: '7/30/22',
        location: 'Lips Cafe Brooklyn, NY',
        ticket: 'https://thegreenroom42.venuetix.com/show/details/ZCoquGtfA7zvtf2l3cQf/1655938800000'
    }
    
];