import React, { Component } from "react";

class Videos extends Component {
  render() {
    return (
      <>
        <div className="container">
          <div className="container">
            <div className="row">
              <div className="col">
                <h3> Videos </h3>
              </div>
            </div>
            <p className="p-3">Under construction - videos coming soon.</p>
          </div>
        </div>
      </>
    );
  }
}
export default Videos;
